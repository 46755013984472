<script setup lang="ts">
interface Props {
    maxWidth?: number, // Ширина модального окна
    maxHeight?: number | null, // Высота модального окна
    styleClass?: string | string[], // классы для стилизации из внешнего компонента
    showCloseButton?: boolean, // показывать крестик закрытия модального окна
}

withDefaults(defineProps<Props>(), {
    maxWidth: 520,
    maxHeight: null,
    styleClass: '',
    showCloseButton: true,
});

const model = defineModel<boolean>();

const emit = defineEmits(['closeDialog']);
/**
 * Функция для закрытия диалога.
 * Вызывает событие 'closeDialog', чтобы уведомить родительский компонент о закрытии.
 */
const onCancel = (): void => {
    emit('closeDialog');
};
</script>

<template>
    <v-dialog 
        v-model="model"
        :maxWidth="maxWidth"
        :maxHeight="maxHeight ? maxHeight : ''"
        :class="['info-dialog', styleClass]"
    >
        <template #default>
            <v-card>
                <v-btn
                    v-if="showCloseButton"
                    class="close-popup"
                    size="x-large"
                    elevation="0"
                    @click="onCancel"
                >
                    <i class="ri-close-large-line"></i>
                </v-btn>

                <v-card-title>
                    <slot name="title" />
                </v-card-title>

                <v-card-text>
                    <slot name="content" />
                </v-card-text>

                <v-card-actions>
                    <slot name="actions"/>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<style scoped lang="scss">
.info-dialog {
    :deep(.v-overlay__content) {
        @include responsive( 'mobile-small' ) {
            margin: 0;
            width: 100% !important;
            max-width: 100% !important;
            bottom: 0;
        }
    }
}

.v-card {
    border-radius: 32px !important;
    position: relative;
    padding: 40px;

    @include responsive( 'mobile-small' ) {
        padding: 32px 24px;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.v-card-title {
    padding: 0;
    font-size: 34px;
    font-weight: 700;
    line-height: 115%;
    letter-spacing: -1%;
    text-transform: uppercase;
    text-align: center;

    @include responsive( 'mobile-small' ) {
        font-size: 24px;
    }
}

.v-card-text {
    padding: 0 !important;
    margin-top: 16px;
}

.v-card-actions {
    margin-top: 40px;
    padding: 0;
    display: flex;

    @include responsive( 'mobile-small' ) {
        margin-top: 32px;
    }
}

.close-popup {
    position: absolute !important;
    right: 0;
    top: 42px;
    transform: translateZ(0) scale(0.85);
    will-change: transform;

    @include responsive( 'mobile-small' ) {
        top: 26px;
    }
}
</style>